<template>
  <b-modal
    id="modalClientPermission"
    :visible="ModalClientPermission"
    title="Permission"
    @show="onShow()"
    @hide="resetModal()"
    size="lg"
    no-close-on-backdrop
    ok-only
    ok-title="Done"
  >
    <b-row>
      <!-- Client Admin Info -->
      <b-col>
        <KTCard title="Client Info">
          <template v-slot:body>
            <b-row class="mb-2" align-v="center">
              <b-col sm="4">
                <label>ID</label>
              </b-col>
              <b-col>
                <b-input readonly :value="client.user_id"></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2" align-v="center">
              <b-col sm="4">
                <label>Username</label>
              </b-col>
              <b-col>
                <b-input readonly :value="client.username"></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2" align-v="center">
              <b-col sm="4">
                <label>Email</label>
              </b-col>
              <b-col>
                <b-input readonly :value="client.email"></b-input>
              </b-col>
            </b-row>
          </template>
        </KTCard>
      </b-col>
      <!-- END client Info -->
    </b-row>
    <b-row>
      <!-- R Permission List -->
      <b-col>
        <KTCard title="Client Admin Permissions">
          <template v-slot:toolbar>
            <b-button-group size="sm">
              <b-button
                @click="assignClientAdminGeneralPermission()"
                variant="primary"
                size="sm"
                ><i class="fa fa-plus"></i> Assign General Permission
              </b-button>
              <b-button @click="savePermission()" variant="primary" size="sm"
                ><i class="fa fa-plus"></i> Save</b-button
              >
            </b-button-group>
          </template>

          <template v-slot:body>
            <b-table
              class="table-vcenter"
              :items="ClientPermission"
              :fields="fields"
              :busy="isBusy"
              head-variant="light"
              show-empty
              outlined
              responsive
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(read_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="updatePermission($event, row.index, statusList.read)"
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(edit_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="updatePermission($event, row.index, statusList.edit)"
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(create_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.create)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(delete_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.delete)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(approve_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.approve)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(maker_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.maker)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(checker_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.checker)
                  "
                  switch
                ></b-form-checkbox>
              </template>
            </b-table>
          </template>
        </KTCard>
      </b-col>
      <!-- END Client Admin Permission List -->
    </b-row>
  </b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import {
  ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION,
  GET_CLIENT_ADMIN_PERMISSION,
  UPDATE_CLIENT_ADMIN_PERMISSION,
} from "@/core/services/store/modules/globaladmin/administration.module";

import SystemHelper from "@/core/services/systemhelper.service";

export default {
  data() {
    return {
      isBusy: true,
      client: {
        user_id: "",
        username: "",
        email: "",
      },
      permissions: [],
      fields: [
        { key: "module_name", label: "Module" },
        { key: "read_status", label: "Read" },
        { key: "edit_status", label: "Edit" },
        { key: "create_status", label: "Create" },
        { key: "delete_status", label: "Delete" },
        { key: "approve_status", label: "Approve" },
        { key: "maker_status", label: "Maker" },
        { key: "checker_status", label: "Checker" },
      ],
      statusList: {
        read: "read_status",
        edit: "edit_status",
        create: "create_status",
        delete: "delete_status",
        approve: "approve_status",
        maker: "maker_status",
        checker: "checker_status",
      },
    };
  },
  computed: {
    ClientPermission() {
      const permission = this.$store.state.globaladmin_administration
        .clientAdminPermissionList;
      console.log("AdminPermision ", permission);
      if (Array.isArray(permission) && permission.length) {
        return permission;
      } else {
        return [];
      }
    },
    // modalClientPermission() {
    //   return this.$store.state.ui.activeModal === "modal-admin-permission";
    // },
    ModalClientPermission() {
      return this.$store.state.ui.activeModal === "modalClientPermission";
    },
  },
  methods: {
    savePermission() {
      // save admin permission changes
      const payload = {
        user_id: this.client.user_id,
        fullUpdatedList: this.permissions,
      };
      SystemHelper.confirmationDialogHandler(
        { title: "CONFIRMATION", html: "Save admin permission?" },
        { action: UPDATE_CLIENT_ADMIN_PERMISSION, payload: payload },
        [
          {
            action: GET_CLIENT_ADMIN_PERMISSION,
            payload: { user_id: this.client.user_id },
          },
        ],
        null
      );
      // this.$store
      //   .dispatch(UPDATE_CLIENT_ADMIN_PERMISSION, payload)
      //   .then((res) => {
      //     if (res.response_code === 2100) {
      //       console.log("UPDATE_CLIENT_ADMIN_PERMISSION SUCCESS");
      //     } else {
      //       console.log("UPDATE_CLIENT_ADMIN_PERMISSION FAILED");
      //     }
      //   });
    },
    assignClientAdminGeneralPermission() {
      // save admin permission changes
      const payload = {
        user_id: this.client.user_id,
      };
      SystemHelper.confirmationDialogHandler(
        { title: "CONFIRMATION", html: "Assign default permission?" },
        { action: ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION, payload: payload },
        [{ action: GET_CLIENT_ADMIN_PERMISSION, payload: payload }],
        null
      );
      // this.$store
      //   .dispatch(ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION, payload)
      //   .then((res) => {
      //     if (res.response_code === 2100) {
      //       console.log("ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION SUCCESS");
      //     } else {
      //       console.log("ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION FAILED");
      //     }
      //   });
    },
    updatePermission(e, index, status) {
      const value = e ? 1 : 0;
      this.permissions[index][status] = value;
    },
    onShow() {
      console.log("showing");
      this.isBusy = true;
      // assign selected admin
      this.client = this.$parent.selectedClient;

      // get admin permission
      const payload = {
        user_id: this.client.user_id,
      };
      this.$store.dispatch(GET_CLIENT_ADMIN_PERMISSION, payload).then((res) => {
        // const data = res.data;
        if (res.response_code == 2100) {
          // permissions placeholder
          this.permissions = [];
          this.ClientPermission.forEach((permission) => {
            this.permissions.push(permission);
          });
        } else {
          this.permissions = [];
        }

        this.isBusy = false;
      });
    },
    resetModal() {
      // console.log(SET_MODAL_STATE);
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
