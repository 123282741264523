<template>
  <b-modal
    id="modalUpdateClient"
    :visible="modalVisibility(modalId)"
    title="Update Client"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="lg"
    no-close-on-backdrop
    ok-title="Submit"
  >
    <KTCard title="Current Info">
      <template #body>
        <!--        <b-row class="mb-2" align-v="center">-->
        <!--          <b-col cols="2">-->
        <!--            <label>User ID</label>-->
        <!--          </b-col>-->
        <!--          <b-col cols="2">-->
        <!--            <b-input-->
        <!--              :value="client.user_id"-->
        <!--              :placeholder="client.user_id.toString()"-->
        <!--              readonly-->
        <!--            ></b-input>-->
        <!--          </b-col>-->

        <!--          <b-col cols="2">-->
        <!--            <label>Name</label>-->
        <!--          </b-col>-->

        <!--          <b-col cols="6">-->
        <!--            <b-input-->
        <!--              :value="client.full_name"-->
        <!--              :placeholder="client.full_name.toString()"-->
        <!--              readonly-->
        <!--            ></b-input>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row class="mb-2" align-v="center">
          <b-col sm="4">
            <label>Username</label>
          </b-col>
          <b-col>
            <b-input
              :value="client.username"
              :placeholder="client.username"
              readonly
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2" align-v="center">
          <b-col sm="4">
            <label>Email</label>
          </b-col>
          <b-col>
            <b-input
              :value="client.email"
              :placeholder="client.email"
              readonly
            ></b-input>
          </b-col>
        </b-row>
      </template>
    </KTCard>

    <KTCard title="New Info">
      <template #body>
        <!--        <b-row class="mb-2" align-v="center">-->
        <!--          <b-col sm="4">-->
        <!--            <label>Username</label>-->
        <!--          </b-col>-->
        <!--          <b-col>-->
        <!--            <base-input-->
        <!--              v-model="form.full_name"-->
        <!--              attribute="Full Name"-->
        <!--              :placeholder="client.full_name"-->
        <!--              :validator="$v.form.full_name"-->
        <!--              :messages="localMessages"-->
        <!--            ></base-input>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row class="mb-2" align-v="center">
          <b-col sm="4">
            <label>Username</label>
          </b-col>
          <b-col>
            <base-input
              v-model="form.username"
              attribute="Username"
              :placeholder="client.username"
              :validator="$v.form.username"
              :messages="localMessages"
            ></base-input>
          </b-col>
        </b-row>
        <b-row class="mb-2" align-v="center">
          <b-col sm="4">
            <label>Email</label>
          </b-col>
          <b-col>
            <base-input
              v-model="form.email"
              attribute="Email"
              :placeholder="client.email"
              :validator="$v.form.email"
              :messages="localMessages"
            ></base-input>
          </b-col>
        </b-row>
      </template>
    </KTCard>
  </b-modal>
</template>

<script>
import {
  UPDATE_COMPANY_USER,
  GET_COMPANY_USER_LIST,
} from "@/core/services/store/modules/globaladmin/administration.module";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";

import { validationMixin } from "vuelidate";
import { email, requiredIf } from "vuelidate/lib/validators";

import SystemHelper from "@/core/services/systemhelper.service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      modalId: "modalUpdateClient",
      client: {
        user_id: "",
        // username: "",
        email: "",
        full_name: "",
      },
      form: {
        // username: null,
        full_name: null,
        email: null,
      },
    };
  },
  validations: {
    form: {
      // full_name: {
      //   required: requiredIf(function () {
      //     return (
      //       !this.form.username && !this.form.email && !this.form.full_name
      //     );
      //   }),
      // },
      username: {
        required: requiredIf(function() {
          return (
            !this.form.username && !this.form.email && !this.form.full_name
          );
        }),
      },
      email: {
        required: requiredIf(function() {
          return (
            !this.form.username && !this.form.email && !this.form.full_name
          );
        }),
        email,
      },
    },
  },
  computed: {
    IsValidate() {
      return this.form.username || this.form.email;
    },
  },
  methods: {
    onShow() {
      this.client = this.$parent.selectedClient;
    },
    onOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      // trigger submit
      this.onSubmit();
    },
    onSubmit() {
      // validate form
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        const payload = {
          company_user_id: this.client.company_user_id,
        };
        if (this.form.username) {
          payload.new_username = this.form.username;
        }
        // if (this.form.full_name) {
        //   payload.new_fullname = this.form.full_name;
        // }
        if (this.form.email) {
          payload.new_email = this.form.email;
        }
        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation" },
          { action: UPDATE_COMPANY_USER, payload: payload },
          [{ action: GET_COMPANY_USER_LIST, payload: this.defaultPayload }],
          this.modalId
        );
        // this.$store.dispatch(UPDATE_COMPANY_USER, payload).then(({ data }) => {
        //   if (data.response_code == 2100) {
        //     this.resetModal();
        //   }
        // });
      }
    },
    resetModal() {
      this.form = {
        username: null,
        full_name: null,
        email: null,
      };
      // console.log(SET_MODAL_STATE);
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
